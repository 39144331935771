import {
    config,
    Module,
    VuexModule,
    Mutation,
    Action
} from "vuex-module-decorators";
import Store from "../index";
import apiSvc from "../../services/api";
import { getApiClient } from "../../services/api";

import {
    SessionDataObject,
    SessionOptions,
    SingleSessionOptions,
    MasterSessionObject
} from "@/types/interfaces";

// Set rawError to true by default on all @Action decorators
config.rawError = true;

const endpoint = "sessions";
const singleSessionEndpoint = "session";

@Module({
    dynamic: true,
    store: Store,
    name: "getSessionData",
    namespaced: true
})
export default class GetSessionsModule extends VuexModule {
    sessionData: MasterSessionObject = {
        onDemand: [],
        upcomingSessions: [],
        currentTimeISO: ""
    };

    singleSession: SessionDataObject = {
        sessionId: "",
        isSponsorVideo: false,
        image: "",
        videoUrl: "",
        videoUrlOnDemand: "",
        date: "",
        description: "",
        startTime: "",
        endTime: "",
        speakers: [],
        title: "",
        currentTimeISO: "",
        onDemandOnly: false,
        notForOnDemand: false,
        speakeasyType: "",
        document: "",
        notForPromo: false
    };

    sessionUpdataPayload: SessionDataObject | Partial<SessionDataObject> = {};

    get sessionOptions() {
        const options = this.context.rootState?.themeConfig?.sessionOptions;
        return options || {};
    }

    get session() {
        return this.singleSession;
    }

    get inPerson(): boolean {
        return Boolean(this.session.inPerson)
    }
    

    get sessionOnDemand() {
        if (this.sessionData) {
            return this.sessionData.onDemand
                .map((session) => {
                    return { ...session, isExpanded: false };
                })
                .filter((item) => {
                    return Boolean(!item.notForOnDemand);
                });
        } else {
            return [];
        }
    }

    get sessionPromos() {
        if (this.sessionData.onDemand) {
            return this.sessionData.onDemand
                .map((session) => {
                    return { ...session, isExpanded: false };
                })
                .filter((item) => {
                    return Boolean(!item.notForPromo);
                });
        } else {
            return [];
        }
    }

    @Mutation
    public setSessions(data: MasterSessionObject) {
        this.sessionData = data;
    }

    @Mutation
    public setSessionPayload(data: SessionDataObject) {
        this.sessionUpdataPayload = data;
    }

    @Mutation setSingleSession(data: SessionDataObject) {
        this.singleSession = data;
    }

    @Action({ commit: "setSessions" })
    getSessionData(options: SessionOptions) {
        const requestParams = {
            showHidden: Boolean(true === options.showHidden)
        };

        return new Promise((resolve, reject) => {
            const token = this.context.rootGetters.idToken;

            return getApiClient()
                .get(`/${endpoint}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    },
                    params: requestParams
                })
                .then((response) => {
                    if (requestParams.showHidden) {
                        const allSessions = [
                            ...response.data.upcomingSessions,
                            ...response.data.onDemand
                        ];
                        response.data.upcomingSessions = allSessions;
                        response.data.onDemand = [];
                    }

                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({})
    getRelatedSessions(session: SessionDataObject) {
        const requestParams = {
            ids: [] as Array<string>
        };

        if (Array.isArray(session.relatedSessions)) {
            requestParams.ids = session.relatedSessions;
        }

        return new Promise((resolve, reject) => {
            const token = this.context.rootGetters.idToken;

            if (!Array.isArray(session.relatedSessions)) {
                return resolve([]);
            }

            return getApiClient()
                .get(`/${endpoint}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    },
                    params: requestParams
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ commit: "setSingleSession" })
    getSingleSession(options: SingleSessionOptions) {
        return new Promise((resolve, reject) => {
            apiSvc
                .getSession(options.sessionId)
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ rawError: true })
    getSession(sessionId: string) {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`/${singleSessionEndpoint}/${sessionId}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ rawError: true })
    editSession() {
        const payload = this.sessionUpdataPayload;
        const { sessionId, ...rest } = payload;
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .put(`/${singleSessionEndpoint}/${sessionId}`, rest, {
                    headers: { Authorization: `bearer ${token}` }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ rawError: true })
    getSpeakeasyAttendee(sessionId: string) {
        const id = sessionId;
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`/${singleSessionEndpoint}/${id}?attendees=true`, {
                    headers: { Authorization: `bearer ${token}` }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ rawError: true })
    getExhibitorSession() {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`/${endpoint}?type=exhibitorList`, {
                    headers: { Authorization: `bearer ${token}` }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ rawError: true })
    getAttendeeSession(attendeeId: string) {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`/${endpoint}?attendeeId=${attendeeId}`, {
                    headers: { Authorization: `bearer ${token}` }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }
}
