import Vue from "vue";
import VueRouter, { RouteConfig, Route } from "vue-router";
import store from "@/store";
import eventHub from "@/event-hub";
import Gate from "../views/Gate.vue";
import { getModule } from "vuex-module-decorators";

import presenceVuexModule from "@/store/vuex-modules/presence";
const presenceStore = getModule(presenceVuexModule);

import signedUrlVuexModule from "@/store/vuex-modules/signed-url";
const signedUrlStore = getModule(signedUrlVuexModule);

Vue.use(VueRouter);

const handleRoutePresenceDelete = async (from: Route) => {
    let fromId = from.params.id;
    let fromName = from.name;
    const lastKnownRouteString = window.localStorage.getItem(
        "last-known-presence"
    );
    let route = from;
    let lastKnownRoute: Partial<Route> = { params: {} };

    if ((!fromName || !fromId) && lastKnownRouteString) {
        try {
            lastKnownRoute = JSON.parse(lastKnownRouteString);
            fromId = lastKnownRoute.params?.id || "";
            fromName = lastKnownRoute.name;
            if (fromId && fromName) {
                route = lastKnownRoute as Route;
            }
        } catch (error) {
            // n/a
        }
    }

    if (fromId && fromName) {
        try {
            await presenceStore.deleteRoutePresence(route);
        } catch (error) {
            // n/a
        }
    }
};

const handleRoutePresence = async (to: Route, from: Route) => {
    /**
     * Call this function as needed in the `beforeEnter` of the relevant route.
     * Not adding this in global `beforeEach` becuase
     * we don't want to slow down navigation where this is not needed.
     */
    const toId = to.params.id;
    const toName = to.name;
    from; // ts complaint

    if (toId && toName) {
        const routeIdentifier = `${toName}_${toId}`;

        try {
            const toAsJsonString = JSON.stringify(to);

            // first
            await presenceStore.addRoutePresence(routeIdentifier);

            // then
            window.localStorage.setItem("last-known-presence", toAsJsonString);
        } catch (error) {
            // n/a
        }

        try {
            const presenceData = (await presenceStore.getRoutePresence(
                routeIdentifier
            )) as Array<Record<string, string>>;
            presenceStore.setRoutePresence(presenceData);
        } catch (error) {
            presenceStore.setRoutePresence([]);
        }
    } else {
        presenceStore.setRoutePresence([]);
    }
};

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "Home",
        component: () => {
            const options = store.getters.getPageOptions("home");
            const homeComponent = options?.componentVariance || [];
            let componentToUse = "DefaultHomePage.vue";

            homeComponent.forEach((item: Record<string, string | boolean>) => {
                if (item.isActive) {
                    const x = item.landingPage as string;
                    componentToUse = x;
                }
            });

            return import(
                /* webpackChunkName: "Home" */ `../components/home/${componentToUse}`
            );
        },
        meta: {
            title: "Home Page"
        }
    },
    {
        path: "/home",
        redirect: {
            name: "Home"
        }
    },
    {
        path: "/gate",
        name: "Gate",
        component: () => {
            const options = store.getters.getPageOptions("gate");
            let gateComponent = options?.components;
            if (!gateComponent) {
                gateComponent = "Gate.vue";
            }
            return import(
                /* webpackChunkName: "Gate" */ `../views/${gateComponent}`
            );
        },
        meta: {
            title: "Portal to Meeting",
            noWrap: true
        },
        props: true,
        children: [
            {
                path: ":jwt",
                props: true,
                component: Gate
            }
        ]
    },
    {
        path: "/attendees",
        component: () =>
            import(
                /* webpackChunkName: "attendees" */ "../views/Attendees.vue"
            ),
        beforeEnter: async (to, from, next) => {
            const activeRoute = await store.dispatch(
                "getNavItemByName",
                "Attendees"
            );

            if (activeRoute?.active) {
                next();
            } else {
                next("/");
            }
        },
        children: [
            {
                name: "Attendees",
                path: "",
                component: () => {
                    const options = store.getters.getPageOptions("attendees");
                    let searchComponent = options?.components?.search;

                    if (!searchComponent) {
                        searchComponent = "AttendeeSearch.vue";
                    }

                    return import(
                        /* webpackChunkName: "attendees" */ `../components/attendee-search/${searchComponent}`
                    );
                },
                meta: {
                    title: "Attendees"
                }
            },
            {
                name: "AttendeeSearchResults",
                path: "results",
                component: () =>
                    import(
                        /* webpackChunkName: "attendees" */ "../components/attendee-search/AttendeeSearchResults.vue"
                    ),
                meta: {
                    title: "Attendee Search Results"
                }
            },
            {
                path: "attendee-profile/:id",
                name: "attendeeProfile",
                props: true,
                component: () =>
                    import(
                        /* webpackChunkName: "attendeeProfile" */ "../components/attendee-profile/AttendeeProfile.vue"
                    ),
                meta: {
                    title: "Attendee Profile Page"
                }
            },
            {
                path: "company-listing/:id",
                name: "companyListing",
                props: true,
                component: () =>
                    import(
                        /* webpackChunkName: "attendee-company-listing" */ "../components/attendee-search/AttendeeCompanyListing.vue"
                    ),
                meta: {
                    title: "Company Attendee Listing"
                }
            },
            {
                name: "AttendeeList",
                path: "list",
                component: () =>
                    import(
                        /* webpackChunkName: "attendees" */ "../components/attendee-search/AttendeeList.vue"
                    ),
                meta: {
                    title: "Attendee List"
                }
            }
        ]
    },
    {
        path: "/sessions",
        name: "Sessions",
        meta: {
            title: "Sessions"
        },
        component: () =>
            import(
                /* webpackChunkName: "sessions" */ `../views/SessionsView.vue`
            ),
        // this will check if this route is active for navigation
        beforeEnter: async (to, from, next) => {
            const activeRoute = await store.dispatch(
                "getNavItemByName",
                "Sessions"
            );

            if (activeRoute?.active) {
                next();
            } else {
                next("/");
            }
        }
    },
    {
        path: "/sessions/:id",
        name: "Session",
        component: () => {
            return import(
                /* webpackChunkName: "session" */ "../views/LiveStream.vue"
            );
        },
        beforeEnter: async (to, from, next) => {
            await handleRoutePresence(to, from);
            next();
        }
    },
    {
        path: "/sessions/poll-results/:id",
        name: "Session Poll Results",
        meta: {
            noWrap: true
        },
        component: () =>
            import(
                /* webpackChunkName: "PollResults" */ "../views/PollResults.vue"
            )
    },
    {
        path: "/meeting/:id",
        name: "MeetingView",
        meta: { noWrap: true },
        component: () => {
            return import(
                /* webpackChunkName: "meeting" */ "../views/Meeting.vue"
            );
        }
    },
    {
        path: "/meeting/:id/developer",
        name: "MeetingDeveloper",
        meta: { noWrap: true },
        component: () =>
            import(/* webpackChunkName: "meeting" */ "../views/Meeting.vue")
    },
    {
        path: "/tradeshow",
        component: () =>
            import(
                /* webpackChunkName: "Tradeshow" */ "../views/Tradeshow.vue"
            ),
        beforeEnter: async (to, from, next) => {
            const activeRoute = await store.dispatch(
                "getNavItemByName",
                "Tradeshow"
            );

            if (activeRoute?.active) {
                next();
            } else {
                next("/");
            }
        },
        children: [
            {
                name: "Tradeshow",
                path: "",
                component: () => {
                    const options = store.getters.getPageOptions("tradeshow");
                    let componentToUse = options?.components?.search;

                    if (!componentToUse) {
                        componentToUse = "search/ExhibitorSearch.vue";
                    }
                    return import(
                        /* webpackChunkName: "tradeshow" */ `../components/tradeshow/${componentToUse}`
                    );
                },
                meta: {
                    title: "Tradeshow"
                }
            },
            // {
            //     path: "results",
            //     name: "ExhibitorSearchResults",
            //     component: () =>
            //         import(
            //             /* webpackChunkName: "tradeshow-results" */ "../components/tradeshow/search/ExhibitorSearchResults.vue"
            //         ),
            //     meta: {
            //         title: "Company Search Result"
            //     }
            // }
        ]
    },
    {
        path: "/tradeshow/exhibitor-details/:id",
        name: "ExhibitorDetails",
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "tradeshow-exhibitor-details" */ "../components/exhibitor-profile/ExhibitorExpandedProfile.vue"
            ),
        meta: {
            title: "Company profile page"
        }
    },
    {
        name: "Resources",
        path: "",
        component: () =>
            import(
                /* webpackChunkName: "Resources" */ "../views/ResourcesView.vue"
            ),
        // this will check if this route is active for navigation
        beforeEnter: async (to, from, next) => {
            const activeRoute = await store.dispatch(
                "getNavItemByName",
                "Resources"
            );

            if (activeRoute?.active) {
                next();
            } else {
                next("/");
            }
        },
        children: [
            {
                name: "resourceComponent",
                path: "/resources",
                component: () => {
                    const options = store.getters.getPageOptions("resources");
                    let componentToUse = options?.componentss;

                    if (!componentToUse) {
                        componentToUse = "Resources.vue";
                    }
                    return import(
                        /* webpackChunkName: "resourceComponent" */ `../components/Resources/${componentToUse}`
                    );
                },
                meta: {
                    title: "Resources"
                }
            }
        ]
    },
    {
        name: "Presenters",
        path: "",
        component: () =>
            import(
                /* webpackChunkName: "PresentersView" */ "../views/PresentersView.vue"
            ),
        // this will check if this route is active for navigation
        beforeEnter: async (to, from, next) => {
            const activeRoute = await store.dispatch(
                "getNavItemByName",
                "Presenters"
            );

            if (activeRoute?.active) {
                next();
            } else {
                next("/");
            }
        },
        // children: [
        //     {
        //         name: "PresentersComponent",
        //         path: "/presenters",
        //         component: () => {
        //             const options = store.getters.getPageOptions("presenters");
        //             let componentToUse = options?.componentss;

        //             if (!componentToUse) {
        //                 componentToUse = "Presenters.vue";
        //             }
        //             return import(
        //                 /* webpackChunkName: "PresentersComponent" */ `../components/Presenters/${componentToUse}`
        //             );
        //         },
        //         meta: {
        //             title: "Presenters"
        //         }
        //     }
        // ]
    },
    {
        path: "/message-center",
        name: "Message-Center",
        meta: {
            title: "Message Center"
        },
        component: () =>
            import(
                /* webpackChunkName: "message-center" */ "../views/MessageCenterView.vue"
            ),
        // this will check if this route is active for navigation
        beforeEnter: async (to, from, next) => {
            const activeRoute = await store.dispatch(
                "getNavItemByName",
                "Message-Center"
            );

            if (activeRoute?.active) {
                next();
            } else {
                next("/");
            }
        }
    },
    {
        path: "/request-meeting",
        name: "CreateActivity",
        meta: {
            title: "Request A Meeting"
        },
        component: () => {
            return import(
                /* webpackChunkName: "request-meeting" */ "../views/CreateActivity.vue"
            );
        },
        beforeEnter: (to, from, next) => {
            const optionChecker = store.getters.isPageOptionActiveInEnv;
            const isActive = optionChecker("createActivity", "isActive");

            if (isActive) {
                next();
            } else {
                next("/");
            }
        }
    },
    {
        path: "/admin",
        component: () =>
            import(/* webpackChunkName: "admin-hub" */ "../views/AdminHub.vue"),
        beforeEnter: (to, from, next) => {
            const isUserAllowed = store.getters;
            setTimeout(() => {
                const isSuperUser = isUserAllowed.userInfo.superuser;
                if (isSuperUser) {
                    next();
                } else {
                    next("/401");
                }
            }, 2000);
        },
        children: [
            {
                path: "",
                name: "AdminDashboard",
                meta: {
                    title: "Admin Dashboard",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin-dashboard" */ "../components/admin/AdminDashboard.vue"
                    )
            },
            {
                path: "schedule",
                name: "AdminSchedule",
                meta: {
                    title: "Admin Schedule",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin-schedule" */ "../components/admin/schedule/AdminScheduleView.vue"
                    )
            },
            {
                path: "sessions",
                name: "AdminSession",
                meta: {
                    title: "Admin session",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin-sessions" */ "../components/admin/sessions/AdminSessionsView.vue"
                    )
            },
            {
                path: "analytics",
                name: "AdminAnalytics",
                meta: {
                    title: "Admin Analytics",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin-analytics" */ "../components/admin/analytics/AdminAnalyticsView.vue"
                    )
            },
            {
                path: "analytics/users",
                name: "AdminAnalyticsOverviewUsers",
                meta: {
                    title: "Admin Analytics: Users",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin-analytics-users" */ "../components/admin/analytics/AnalyticsOverviewUsers.vue"
                    )
            },
            {
                path: "analytics/users/:attendeeId",
                name: "AdminAnalyticsDetailUser",
                meta: {
                    title: "Admin Analytics: User Detail",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin-analytics-user" */ "../components/admin/analytics/AnalyticsDetailUser.vue"
                    )
            },
            {
                path: "analytics/exhibitors",
                name: "AdminAnalyticsOverviewExhibitors",
                meta: {
                    title: "Admin Analytics: Exhibitors",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin-analytics-exhibitors" */ "../components/admin/analytics/AnalyticsOverviewExhibitors.vue"
                    )
            },
            {
                path: "analytics/exhibitors/:sponsorId",
                name: "AdminAnalyticsDetailExhibitor",
                meta: {
                    title: "Admin Analytics: Exhibitor Detail",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin-analytics-exhibitor" */ "../components/admin/analytics/AnalyticsDetailExhibitor.vue"
                    )
            },
            {
                path: "analytics/posters",
                name: "AdminAnalyticsOverviewPosters",
                meta: {
                    title: "Admin Analytics: Posters",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin-analytics-posters" */ "../components/admin/analytics/AnalyticsOverviewPosters.vue"
                    )
            },
            {
                path: "analytics/posters/:posterId",
                name: "AdminAnalyticsDetailPoster",
                meta: {
                    title: "Admin Analytics: Poster Detail",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin-analytics-posters" */ "../components/admin/analytics/AnalyticsDetailPoster.vue"
                    )
            },
            {
                path: "analytics/livestreams",
                name: "AdminAnalyticsOverviewLivestreams",
                meta: {
                    title: "Admin Analytics: Sessions",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin-analytics-livestreams" */ "../components/admin/analytics/AnalyticsOverviewLivestreams.vue"
                    )
            },
            {
                path: "analytics/livestreams/:sessionId",
                name: "AdminAnalyticsDetailLivestream",
                meta: {
                    title: "Admin Analytics: Session Detail",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin-analytics-livestreams" */ "../components/admin/analytics/AnalyticsDetailLivestream.vue"
                    )
            },
            {
                path: "analytics/speakeasies",
                name: "AdminAnalyticsOverviewSpeakeasies",
                meta: {
                    title: "Admin Analytics: Speakeasies",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin-analytics-speakeasies" */ "../components/admin/analytics/AnalyticsOverviewSpeakeasies.vue"
                    )
            },
            {
                path: "analytics/speakeasies/:sessionId",
                name: "AdminAnalyticsDetailSpeakeasy",
                meta: {
                    title: "Admin Analytics: Speakeasy Detail",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin-analytics-speakeasies" */ "../components/admin/analytics/AnalyticsDetailSpeakeasy.vue"
                    )
            },
            {
                path: "analytics/polls",
                name: "AdminAnalyticsOverviewPolls",
                meta: {
                    title: "Admin Analytics: Polls",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin-analytics-polls" */ "../components/admin/analytics/AnalyticsOverviewPolls.vue"
                    )
            },
            {
                path: "analytics/polls/:sessionId",
                name: "AdminAnalyticsDetailPoll",
                meta: {
                    title: "Admin Analytics: Poll Detail",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin-analytics-polls" */ "../components/admin/analytics/AnalyticsDetailPoll.vue"
                    )
            },
            {
                path: "analytics/favorites",
                name: "AdminAnalyticsOverviewFavorites",
                meta: {
                    title: "Admin Analytics: Favorites",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin-analytics-favorites" */ "../components/admin/analytics/AnalyticsOverviewFavorites.vue"
                    )
            },
            {
                path: "analytics/session-evals",
                name: "AdminAnalyticsOverviewSessionEvals",
                meta: {
                    title: "Admin Analytics: Session Evaluations",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin-analytics-favorites" */ "../components/admin/analytics/AnalyticsOverviewSessionEvals.vue"
                    )
            },
            {
                path: "analytics/qotd",
                name: "AdminAnalyticsOverviewQotd",
                meta: {
                    title: "Admin Analytics: Question of the Day",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin-analytics-qotd" */ "../components/admin/analytics/AnalyticsOverviewQuestionOfTheDay.vue"
                    )
            },
            {
                path: "analytics/qotd/:questionId",
                name: "AdminAnalyticsDetailQotd",
                meta: {
                    title: "Admin Analytics: Question of the Day Detail",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin-analytics-qotd" */ "../components/admin/analytics/AnalyticsDetailQuestionOfTheDay.vue"
                    )
            },
            {
                path: "analytics/messages",
                name: "AdminAnalyticsOverviewMessages",
                meta: {
                    title: "Admin Analytics: Messages",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin-analytics-messages" */ "../components/admin/analytics/AnalyticsOverviewMessages.vue"
                    )
            },
            {
                path: "analytics/appointments",
                name: "AdminAnalyticsOverviewAppointments",
                meta: {
                    title: "Admin Analytics: Appointments",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin-analytics-appointments" */ "../components/admin/analytics/AnalyticsOverviewAppointments.vue"
                    )
            },
            {
                path: "editSession",
                name: "EditSession",
                props: true,
                meta: {
                    title: "Edit Session",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "edit-session" */ "../components/admin/schedule/EditSession.vue"
                    )
            },
            {
                path: "options",
                name: "AdminOptions",
                props: true,
                meta: {
                    title: "Edit Site Options",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin-options" */ "../components/admin/options/AdminOptionsView.vue"
                    )
            },
            {
                path: "superusers",
                name: "Superusers",
                props: true,
                meta: {
                    title: "Superusers",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin-superusers" */ "../components/admin/superuser/SuperUsersPanel.vue"
                    )
            }
        ],
        meta: {
            title: "Admin Panel",
            noWrap: true
        }
    },
    {
        name: "Schedule",
        path: "/schedule",
        component: () => {
            return import(
                /* webpackChunkName: "Schedule" */ `../views/SessionsView.vue`
            );
        },
        meta: {
            title: "Schedule"
        },
        // this will check if this route is active for navigation
        beforeEnter: async (to, from, next) => {
            const activeRoute = await store.dispatch(
                "getNavItemByName",
                "Schedule"
            );

            if (activeRoute?.active) {
                next();
            } else {
                next("/");
            }
        }
    },
    {
        path: "/additional-info",
        name: "AdditionalInfo",
        props: true,
        meta: {
            title: "Additional Info",
            contentOption: "additionalInfoContent"
        },
        component: () =>
            import(
                /* webpackChunkName: "user-content-page" */ "../views/UserContentPage.vue"
            ),
        beforeEnter: async (to, from, next) => {
            const activeRoute = await store.dispatch(
                "getNavItemByName",
                "AdditionalInfo"
            );

            if (activeRoute?.active) {
                next();
            } else {
                next("/");
            }
        }
    },
    {
        path: "/help",
        name: "Help",
        meta: {
            title: "Help",
            contentOption: "helpContent"
        },
        component: () =>
            import(
                /* webpackChunkName: "user-content-page" */ "../views/UserContentPage.vue"
            ),
        beforeEnter: async (to, from, next) => {
            const activeRoute = await store.dispatch(
                "getNavItemByName",
                "Help"
            );

            if (activeRoute?.active) {
                next();
            } else {
                next("/");
            }
        }
    },
    {
        path: "/live-stream/:id",
        name: "live stream",
        component: () =>
            import(
                /* webpackChunkName: "live-stream" */ "../views/LiveStream.vue"
            )
    },
    {
        path: "/mg-live",
        name: "StreamVideoLive",
        meta: {
            title: "Live Stream",
            noWrap: true
        },
        component: () => {
            const options = store.getters.getPageOptions("StreamVideoLive");
            let componentToUse = options.components;

            if (!componentToUse) {
                componentToUse = "StreamVideoLive.vue";
            }

            return import(
                /* webpackChunkName: "live-stream" */ `../views/${componentToUse}`
            );
        }
    },
    {
        path: "/entrance",
        name: "Entrance",
        meta: {
            noWrap: true,
            title: "Embarking on a journey to your meeting..."
        },
        component: () =>
            import(
                /* webpackChunkName: "transitions" */ "../views/EntranceAnimation.vue"
            )
    },
    {
        path: "/posters",
        component: () =>
            import(
                /* webpackChunkName: "PostersView" */ "../views/PostersView.vue"
            ),
        beforeEnter: async (to, from, next) => {
            const activeRoute = await store.dispatch(
                "getNavItemByName",
                "Posters"
            );

            if (activeRoute?.active) {
                next();
            } else {
                next("/");
            }
        },
        children: [
            {
                name: "Posters",
                path: "",
                component: () =>
                    import(
                        /* webpackChunkName: "Posters" */ "../components/sessions/posters/PostersGridDisplay.vue"
                    )
            },
            {
                name: "ModifyPoster",
                path: "EditPoster",
                props: true,
                component: () =>
                    import(
                        /* webpackChunkName: "CreatePoster" */ "../components/sessions/posters/EditPoster.vue"
                    )
            }
        ]
    },
    {
        name: "PosterDetails",
        path: "/posters/poster-details/:id",
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "postersDetails" */ "../components/sessions/posters/PosterDetails.vue"
            )
    },
    {
        path: "/networking/:slug/:id",
        name: "NetworkingLanding",
        props: true,
        meta: {
            title: "Network landing page"
        },
        component: () =>
            import(
                /* webpackChunkName: "Networking" */ "../components/networking/LandingPage.vue"
            )
    },
    {
        path: "/awards",
        name: "awards",
        component: () =>
            import(
                /* webpackChunkName: "awards" */ "../components/static-pages/Awards.vue"
            ),
        beforeEnter: async (to, from, next) => {
            const activeRoute = await store.dispatch(
                "getNavItemByName",
                "awards"
            );

            if (activeRoute?.active) {
                next();
            } else {
                next("/");
            }
        }
    },
    {
        path: "/QOTD",
        name: "QOTD",
        meta: {
            title: "Question Of The Day"
        },
        component: () =>
            import(
                /* webpackChunkName: "QOTD" */ "../components/question-comment/QuestionComponentLanding.vue"
            )
    },
    {
        name: "QuestionDetails",
        path: "/QOTD/question-details/:qid",
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "QuestionDetails" */ "../components/question-comment/QuestionDetails.vue"
            )
    },
    {
        path: "/401",
        name: "NotAuthorized",
        meta: {
            noWrap: true,
            title: "Not Authorized"
        },
        component: () =>
            import(
                /* webpackChunkName: "NotAuthorized" */ "../views/NotAuthorized.vue"
            )
    },
    {
        path: "*",
        name: "NotFound",
        meta: {
            title: "Not Found"
        },
        component: () =>
            import(/* webpackChunkName: "NotFound" */ "../views/NotFound.vue")
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.query?.noScroll === "true") {
            return null;
        }

        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

router.beforeEach(async (to, from, next) => {
    const toRoute = to.matched.find((record) => to.name === record.name);
    const isSiteActive = store.getters.isSiteActive;
    const isGateRoute = to.path.startsWith("/gate");
    const isAuthorized = store.getters.isAuthorized;

    // update page title
    document.title =
        to?.meta?.title || store.getters.conferenceName || "BeSpeake";

    // handle app wrapper display
    if (toRoute?.meta?.noWrap) {
        store.dispatch("disableWrapper");
    } else {
        store.dispatch("enableWrapper");
    }

    // handle authentication
    if (!isSiteActive && !isGateRoute) {
        return store.dispatch("logout");
    } else if (
        store.state.themeConfig.forceAuth &&
        !isAuthorized &&
        !isGateRoute
    ) {
        localStorage.setItem("storedPath", to.path);
        return next({ path: "/gate" });
    } else if (isAuthorized) {
        const allowedMeetingDateTimes =
            store.state.settingsVuexModule.allowedMeetingDateTimes;
        const userInfo = store.getters.userInfo;
        let signedUrlAddress = "";
        let signedUrlIsExpired = true;

        if (0 === allowedMeetingDateTimes.length) {
            eventHub.$emit("request-app-settings");
        }

        try {
            signedUrlIsExpired = await signedUrlStore.IsSignedUrlExpired();
            signedUrlAddress = signedUrlStore.signedUrlAddress;
        } catch (error) {
            // n/a
        }

        if (signedUrlIsExpired || !signedUrlAddress) {
            signedUrlStore.setRouterHasRequestedSignedUrl(true);
            eventHub.$emit("request-signed-url");
        }

        if (!userInfo.id) {
            try {
                await store.dispatch("getUserinfo");
            } catch (error) {
                // n/a
            }
        }

        try {
            const route = from;
            await handleRoutePresenceDelete(route);
        } catch (error) {
            // n/a
        }

        if (store.getters.firstLoginSettings.enabled) {
            /**
             * Because this will create a loop if not handled properly, only allow paths explicitly
             *   allowed here. Then any paths in the allowedPaths array below *must* have code to
             *   set the first login variable after doing whatever they're supposed to do.
             */
            const allowedPaths = ["/attendees/attendee-profile/"];
            const path = store.getters.firstLoginSettings.path;

            if (!userInfo.completedFirstLogin && allowedPaths.includes(path)) {
                const actualPath =
                    path === "/attendees/attendee-profile/"
                        ? path + userInfo.id
                        : path;

                if (to.path !== actualPath) {
                    return next({ path: actualPath });
                }
            }
        }
    }

    // avoid code here.
    // add code in above conditional block.

    // this is last
    return next();
});

export default router;
