


























































































import Vue from "vue";
// import { defineComponent } from "vue";
import store from "@/store";
import {
    ActiveNavigation,
    SiteNavigationItem,
    SiteOptions
} from "@/types/interfaces";

import Spinners from "@/components/utilities/Spinners.vue";
import RouterLinkOrAnchorTag from "@/components/shared/RouterLinkOrAnchorTag.vue";

export default Vue.extend({
    components: {
        Spinners,
        RouterLinkOrAnchorTag
    },
    data() {
        return {
            networkingNotificationsInterval: -1,
            open: false,
            headeroffsetHeight: "",
            loading: true
        };
    },
    props: {
        cssVariables: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    created() {
        this.getNav();
        store.dispatch("getNetworkingNotifications");

        (this as any).handleNetworkingNotificationsInterval();
    },

    beforeDestroy() {
        window.clearInterval((this as any).networkingNotificationsInterval);
    },
    /**
     * Computed
     */
    computed: {
        optionsStore(): any {
            return this.$store.state.optionsVuexModule;
        },

        navRoutes(): Array<SiteNavigationItem> {
            const allRoutes = this.options.activeNavigation || [];

            //    const allRoutes = Vue.prototype
            //     .MgSortByProperty(this.$store.getters.activeNavigation, "order")
            //     .filter((obj: ActiveNavigation) => {
            //         return obj.active;
            //     });

            // return allRoutes;
            return allRoutes
                .filter((item: ActiveNavigation) => {
                    return item.active;
                })
                .sort((curr: ActiveNavigation, nxt: ActiveNavigation) => {
                    return curr.order - nxt.order;
                });
        },
        headerOptions(): boolean {
            return store.getters.layoutOptions?.headerOptions || {};
        },
        conferenceYear(): number {
            return store.getters.conferenceYear;
        },
        conferenceTitle(): string {
            return store.getters.conferenceName;
        },
        talkUnreads() {
            return store.getters.talkUnreads;
        },
        networkingUnreads() {
            return store.getters.networkingNotificationsUnseen;
        },
        route() {
            return store.state.route;
        },
        options(): SiteOptions {
            return this.optionsStore.options;
        }
    },
    watch: {
        route: function() {
            if ("Schedule" === (this as any).route.name) {
                window.clearInterval(
                    (this as any).networkingNotificationsInterval
                );
            } else {
                (this as any).handleNetworkingNotificationsInterval();
            }

            /**
             * need to watch active state for nav bar when it is "/" on this route
             as nav bar item seems ignoring empty routes
            **/
            const eL = document.querySelector("a#Home");
            if (eL) {
                if ((this as any).route.name === "Home") {
                    eL!.classList.add("router-link-active");
                } else {
                    eL!.classList.remove("router-link-active");
                }
            }
        }
    },
    methods: {
        handleNetworkingNotificationsInterval() {
            window.clearInterval((this as any).networkingNotificationsInterval);

            const ONE_SECOND = 1000;
            (this as any).networkingNotificationsInterval = window.setInterval(
                () => {
                    (this as any).$store.dispatch("getNetworkingNotifications");
                },
                ONE_SECOND * 30
            );
        },

        openNav() {
            // document.getElementById("navSidePanel")!.style.width = "250px";
            (this as any).open = !(this as any).open;
        },

        closeNav() {
            // document.getElementById("navSidePanel")!.style.width = "0";
            (this as any).open = false;
        },

        handleNavicon(event: Event, targetSelector: string): void {
            const el = event.target as HTMLAnchorElement;
            const nodeTarget = document.getElementById(targetSelector);

            // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
            if (el) {
                el.classList.toggle("is-active");
            }

            if (nodeTarget) {
                nodeTarget.classList.toggle("is-active");
            }
        },

        shouldShowUnread(routePath = "") {
            const case1 =
                Boolean("/message-center" === routePath) &&
                Boolean((this as any).getUnread("/message-center") > 0);
            const case2 =
                "/schedule" === routePath &&
                Boolean((this as any).getUnread("/schedule") > 0);

            return case1 || case2;
        },

        getUnread(routePath = "") {
            let returnValue = 0;

            if ("/message-center" === routePath) {
                returnValue = (this as any).talkUnreads;
            } else if ("/schedule" === routePath) {
                returnValue = (this as any).networkingUnreads;
            }

            return returnValue;
        },

        async getNav() {
            await this.$store.dispatch("getOptions", ["activeNavigation"]);
            this.loading = false;
        }
    }

    /**
     * Watchers
     */
    // @Watch("route")
    // routeChanged() {
    //     if ("Schedule" === this.route.name) {
    //         window.clearInterval(this.networkingNotificationsInterval);
    //     } else {
    //         this.handleNetworkingNotificationsInterval();
    //     }

    //     /**
    //      * need to watch active state for nav bar when it is "/" on this route
    //      as nav bar item seems ignoring empty routes
    //     **/
    //     const eL = document.querySelector("a#Home");
    //     if (eL) {
    //         if (this.route.name === "Home") {
    //             eL!.classList.add("router-link-active");
    //         } else {
    //             eL!.classList.remove("router-link-active");
    //         }
    //     }
    // }

    /**
     * Lifecycle
     */

    /**
     * Methods
     */
});
