


































// Base
import Vue from "vue";
// import { defineComponent } from "vue";
// import { Component, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import eventHub from "@/event-hub";
import { a11yFixBuefyModalAriaAttrs } from "@/services/a11y";

// Types
import { Route } from "vue-router";
import {
    SessionDataObject,
    PendingSessionEvaluation
} from "@/types/interfaces";

// Vuex Modules
import sessionEvalVuexModule from "@/store/vuex-modules/session-evaluations";
import sessionVuexModule from "@/store/vuex-modules/getSessionData";
const evalStore = getModule(sessionEvalVuexModule);
const sessionStore = getModule(sessionVuexModule);

// SurveyJS setup
import * as SurveyVue from "survey-vue";
import EvalsListVue from "./sessions/EvalsList.vue";
const Survey = SurveyVue.Survey;
const defaultQuestions = [
    {
        type: "rating",
        name: "question1",
        title: "Please rate the quality of the CONTENT of the session:",
        hideNumber: true,
        isRequired: true
    },
    {
        type: "rating",
        name: "question2",
        title: "Please rate the quality of the PRESENTATION of the session:",
        hideNumber: true,
        isRequired: true
    },
    {
        type: "radiogroup",
        name: "question3",
        title:
            "Please rate the relevance of the session to you, your job and your company:",
        hideNumber: true,
        isRequired: true,
        choices: [
            "Extremely relevant",
            "Very relevant",
            "Somewhat relevant",
            "Little relevance",
            "Not relevant"
        ]
    },
    {
        type: "comment",
        name: "question4",
        hideNumber: true,
        title: "Any other comments?"
    }
];
SurveyVue.StylesManager.applyTheme("modern");

interface SessionEvaluationText {
    preEval: string;
    finished: string;
    heading: string;
}

export default Vue.extend({
    components: {
        Survey
    },
    data() {
        return {
            loading: false,
            evaluation: null as SurveyVue.SurveyModel | null,
            publicPath: process.env.BASE_URL as string,
            finishedSurvey: false
        };
    },
    computed: {
        route(): Route {
            return this.$route;
        },

        currentRouteName(): string {
            return this.route?.name || "";
        },

        isAllowed(): boolean {
            const disableOnRoutes = ["MeetingView", "MeetingDeveloper"];
            const routeIsAllowed = Boolean(
                !disableOnRoutes.includes(this.currentRouteName)
            );
            const evalIsForSessionBeingViewed = Boolean(
                this.currentSessionBeingViewed === this.activeSessionId
            );

            return !evalIsForSessionBeingViewed && routeIsAllowed;
        },

        currentSessionBeingViewed(): string {
            let returnValue = "";
            const isSessionRoute = Boolean("Session" === this.currentRouteName);

            if (isSessionRoute) {
                returnValue = this.route.params.id;
            }

            return returnValue;
        },

        sessionData() {
            return sessionStore.session;
        },

        isProduction(): boolean {
            return this.$store.getters.isProduction;
        },

        siteLogo(): string {
            return this.$store.getters.logo;
        },

        sessionName(): string {
            return evalStore.sessionName;
        },

        isActive(): boolean {
            return this.isAllowed && evalStore.isActive;
        },

        activeSessionId(): string {
            return evalStore.sessionId;
        },

        surveyObj(): SurveyVue.SurveyModel | null {
            return this.evaluation;
        },

        evalOptions(): any {
            return this.$store.getters.getPageOptions("sessionEvals");
        },

        questions(): any {
            return this.evalOptions &&
                Array.isArray(this.evalOptions.questions) &&
                this.evalOptions.questions.length
                ? this.evalOptions.questions
                : defaultQuestions;
        },

        evalText(): SessionEvaluationText {
            const useName =
                this.evalOptions && this.evalOptions.includeSessionNameInHeader;
            const preReplaceHeading =
                this.evalOptions && this.evalOptions.heading
                    ? this.evalOptions.heading
                    : useName
                    ? "Thanks for attending <name>!"
                    : "Thanks for attending our session!";
            const postReplaceHeading = useName
                ? preReplaceHeading.replace("<name>", this.sessionName)
                : preReplaceHeading;

            return {
                preEval:
                    this.evalOptions && this.evalOptions.preEvalText
                        ? this.evalOptions.preEvalText
                        : "We'd like your feedback so we can improve events in the future.",
                finished:
                    this.evalOptions && this.evalOptions.finishedText
                        ? this.evalOptions.finishedText
                        : "Thanks for submitting your feedback!",
                heading: postReplaceHeading
            };
        },

        myPendingEvals(): Array<PendingSessionEvaluation> {
            return evalStore.myPendingEvals;
        }
    },
    watch: {
        route(to, from) {
            this.handleRouteChange(to, from);
        }
    },

    created() {
        this.initializeSurvey();

        eventHub.$on("BuefyModalBound", this.handleBuefyModalFixes);
    },

    beforeDestroy() {
        eventHub.$off("BuefyModalBound", this.handleBuefyModalFixes);
    },

    methods: {
        async handleRouteChange(to: Route, from: Route) {
            if (from.name === "Session") {
                await evalStore.getMyPendingEvals();

                const sessionData = this.myPendingEvals.find(
                    (evaluation) => evaluation.sessionId === from.params.id
                );

                if (sessionData && !sessionData.hasCompleted) {
                    evalStore.activateSessionEval(sessionData);
                }
            }
        },
        closeModal() {
            this.finishedSurvey = false;

            if (this.evaluation) {
                this.evaluation.clear(true, true);
                this.evaluation.render();
            }

            evalStore.deactivateSessionEval();

            evalStore.getMyPendingEvals();
        },

        initializeSurvey() {
            const modelInput = {
                pages: [
                    {
                        name: "page1",
                        elements: this.questions
                    }
                ]
            };
            this.evaluation = new SurveyVue.Model(modelInput);
            this.evaluation.completedHtml =
                "<p>Thanks for submitting your evaluation!</p>";
            this.evaluation.onComplete.add(this.completeSurvey);
        },

        // TODO: Create or discover interface for SurveyJS options to get rid of any type
        async completeSurvey(
            result: SurveyVue.SurveyModel,
            options: any
        ): Promise<any> {
            const logData = {
                type: "SessionEvaluation",
                sessionId: this.activeSessionId,
                sessionName: this.sessionName,
                answers: JSON.stringify(result.data)
            };

            await this.$store
                .dispatch("appendLogEntry", logData)
                .catch((err) => console.log(err));

            this.finishedSurvey = true;
        },

        handleBuefyModalFixes(el: HTMLElement) {
            a11yFixBuefyModalAriaAttrs(el);
        }
    }
});
