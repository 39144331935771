















import Vue from "vue";


export default Vue.extend ({
    props: {
        accountHandle: {
            type: String,
            default: ""
        },
        height: {
            type: String,
            default: "",
        },
        width: {
            type: String,
            default: ""
        }
    },
    computed: {

        defaultOptions(): Record<string,string> {
            return this.$store.getters.getDefaultTwitterSettings;
        },
    
        /**
         * We want to use the default settings for the site most of the time, but give the props preference
         * so we can override them as needed.
         */
        actualAccountHandle(): string {
            return this.accountHandle
                ? this.accountHandle
                : this.defaultOptions?.accountHandle
                ? this.defaultOptions.accountHandle
                : "";
        },
    
        actualHeight(): string {
            return this.height
                ? this.height
                : this.defaultOptions?.height
                ? this.defaultOptions.height
                : "600";
        },
    
        actualWidth(): string {
            return this.width
                ? this.width
                : this.defaultOptions?.width
                ? this.defaultOptions.width
                : "";
        }
    },
    methods: {
        reloadTwitter() {
            if (window.twttr) {
                window.twttr.widgets.load();
            }
        }
    },
    mounted() {
        this.reloadTwitter();
    }

})
