import store from "@/store";
import axios from "axios";
import TextEncodingPolyfill from "text-encoding";

interface AuthConfig {
    loginURL: string;
    logoutURL: string;
    tokenURL: string;
    clientId: string;
}

interface AuthPayload {
    idToken: string;
    refreshToken?: string;
    accessToken?: string;
    expires?: number;
}

/**
 * OIDC Authentication with MatrixMaxx
 *   Authorization Grant w/ PKCE
 *   Scope: openid
 *   challenge: sha256/base 64 url encoded
 */

// HELPERS
/**
 * Generates a cryptographically secure random string
 * of variable length.
 *
 * The returned string is also url-safe.
 *
 * @param {Number} length the length of the random string.
 * @returns {String}
 */
function randomString(length: number) {
    const validChars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    array = array.map((x) => validChars.charCodeAt(x % validChars.length));
    return String.fromCharCode(...array);
}

/**
 * Takes a base64 encoded string and returns a url encoded string
 * by replacing the characters + and / with -, _ respectively,
 * and removing the = (fill) character.
 *
 * @param {String} input base64 encoded string.
 * @returns {String}
 */
function urlEncodeB64(str: string) {
    return str
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");
}

/**
 * Takes an ArrayBuffer and convert it to Base64 url encoded string.
 * @param {ArrayBuffer} input
 * @returns {String}
 */
function bufferToBase64UrlEncoded(input: ArrayBuffer) {
    const bytes = new Uint8Array(input);
    return urlEncodeB64(window.btoa(String.fromCharCode(...bytes)));
}

/**
 * Returns the sha256 digst of a given message.
 * This function is async.
 *
 * @param {String} message
 * @returns {Promise<ArrayBuffer>}
 */
function sha256(message: string) {
    let encoder;
    if (typeof TextEncoder !== "function") {
        encoder = new TextEncodingPolyfill.TextEncoder();
    } else {
        encoder = new TextEncoder();
    }
    const data = encoder.encode(message);
    return window.crypto.subtle.digest("SHA-256", data);
}

function parseJWT(token: string) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split("")
            .map(function(c) {
                return (
                    "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
                );
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
}

// END HELPERS

const auth = {
    async getAuthToken(authConfig: AuthConfig) {
        const verifier = randomString(45);
        const challenge = await sha256(verifier).then(bufferToBase64UrlEncoded);
        localStorage.setItem("verifier", verifier);
        const params = new URLSearchParams();
        params.append("response_type", "code");
        params.append("client_id", authConfig.clientId);
        params.append("code_challenge", challenge);
        params.append("code_challenge_method", "S256");
        params.append("scope", "openid profile email user_metadata");
        params.append("state", "");
        params.append("redirect_uri", `${window.location.origin}/gate`);
        window.location.href = `${authConfig.loginURL}?${params.toString()}`;
    },
    async getAccessToken(
        authConfig: AuthConfig,
        tokenParams: any
    ): Promise<AuthPayload> {
        const verifier = localStorage.getItem("verifier") || "";
        const params = new URLSearchParams();
        params.append("grant_type", "authorization_code");
        params.append("code", tokenParams.code);
        params.append("client_id", authConfig.clientId);
        params.append("code_verifier", verifier);
        params.append("scope", "openid profile email user_metadata");
        params.append("state", "");
        params.append("redirect_uri", `${window.location.origin}/gate`);
        return axios.post(authConfig.tokenURL, params).then((response) => {
            // console.log(response);
            localStorage.removeItem("verifier");
            const decoded = parseJWT(response.data.id_token);
            return {
                idToken: response.data.id_token,
                accessToken: response.data.access_token,
                refreshToken: response.data.refresh_token,
                expires: decoded.exp
            };
        });
    },
    async refreshToken(authConfig: AuthConfig, refreshToken: string) {
        const params = new URLSearchParams();
        params.append("grant_type", "refresh_token");
        params.append("refresh_token", refreshToken);
        params.append("client_id", authConfig.clientId);
        return axios.post(authConfig.tokenURL, params).then((response) => {
            return {
                idToken: response.data.id_token,
                accessToken: response.data.access_token,
                refreshToken: response.data.refresh_token
            };
        });
    }
};

/* CHA Authentication Mechanism
   This uses a custom web service that is outside of our default OAuth Flow
   
*/
const chaAuth = {
    async getAuthToken(authConfig: AuthConfig) {
        window.location.href = authConfig.loginURL;
    },
    async getAccessToken(
        // eslint-disable-next-line
        authConfig: AuthConfig,
        // eslint-disable-next-line
        tokenParams: any
    ): Promise<AuthPayload> {
        return new Promise((resolve) => {
            resolve({
                idToken: `${tokenParams.WebUserID}#${tokenParams.token}`,
                // cha session will expire after 5 days hours and no refresh
                expires: new Date().getTime() / 1000 + (60*60*24*5)
            });
        });
    },
    // eslint-disable-next-line
    async refreshToken(authConfig: AuthConfig, refreshToken: string) {
        return new Promise((resolve, reject) => {
            reject(new Error("Refresh token is not implemented for CHA auth"));
        });
    }
};

export default {
    oauth: auth,
    cha: chaAuth
};
