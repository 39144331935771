/**
 * TEMPORARY until buefy fixes this
 * Buefy incorrectly uses the syntax xx-label instead of xx--tab when referencing tabs
 * @param htmlElement
 */
export const a11yFixBuefyTabsAriaAttrs = function(htmlElement: HTMLElement) {
    const navElement = htmlElement.querySelector("nav");
    const tabsUl = htmlElement.querySelector(".tabs > ul");
    const tabItems = htmlElement.querySelectorAll(".tab-item");

    if (navElement) {
        navElement.removeAttribute("aria-orientation");
        navElement.setAttribute("role", "navigation");
    }

    if (tabsUl && !tabsUl.getAttribute("role")) {
        tabsUl.setAttribute("role", "tablist");
    }

    tabItems.forEach((element) => {
        const arialLabeledBy = element.getAttribute("aria-labelledby");
        let newAriaLabeledBy = "";
        if (arialLabeledBy && arialLabeledBy.includes("-label")) {
            newAriaLabeledBy = arialLabeledBy.replace("label", "tab");
            element.setAttribute("aria-labelledby", newAriaLabeledBy);
        }
    });
};

/**
 * TEMPORARY
   Work around. Add href so screen readers can pick up tabs.
   fix will be available in future buefy version:
   https://github.com/buefy/buefy/pull/2744
 * @param htmlElement 
 */
export const a11yFixBuefyTabHrefs = function(htmlElement: HTMLElement) {
    const tabLinks = htmlElement.querySelectorAll(".tabs a");
    [...tabLinks].forEach((x) => {
        const item = x as HTMLAnchorElement;
        if (!item.getAttribute("href")) {
            item.href = "#";
            item.setAttribute(
                "aria-label",
                `toggle pannel for ${item.innerText} sessions`
            );
        }
    });
};

/**
 * TEMPORARY until buefy fixes this
 * https://github.com/buefy/buefy/issues/3318
 */
export const a11yFixBuefyNotificationAriaAttrs = function(
    htmlElement: HTMLElement
) {
    const ariaHiddenItems = htmlElement.querySelectorAll("[aria-hidden]");

    ariaHiddenItems.forEach((item) => {
        const ariaHiddenValue = item.getAttribute("aria-hidden") || "";
        if ("" === ariaHiddenValue.trim()) {
            item.removeAttribute("aria-hidden");
        }
    });
};

/**
 * TEMPORARY until buefy fixes this
 * https://github.com/buefy/buefy/issues/3318
 */
export const a11yFixBuefyModalAriaAttrs = function(htmlElement: HTMLElement) {
    const closeButton = htmlElement.querySelector(".modal-close");
    if (closeButton) {
        closeButton.setAttribute("aria-label", "close modal");
    }
};

/**
 * Maybe temporary fix, though this issue has been around since at least 2018 from my research so maybe permanent also.
 *
 * Adds a title attribute to an oEmbed iframe that's a child of the element being observed here by getting the title from
 * a data-video-title attribute on the element, then setting it on the child iframe.
 *
 * This is necessary because oEmbed doesn't add the title attribute to iframes which is required for accessibility.
 *
 * @param htmlElement An element with a child iframe representing an oEmbed video.
 */
export const a11yFixIframeTitleAttr = function(htmlElement: HTMLElement) {
    const title = htmlElement.dataset.videoTitle
        ? htmlElement.dataset.videoTitle
        : "Featured Video";
    const iframe = htmlElement.querySelector("iframe");
    iframe?.setAttribute("title", title);
};

/**
 * Note: This is intended to ONLY manipulate `DOM element attributes` for 3rd party component (e.g. buefy) markup.
 * Any other mutations will result infinite loop
 *
 * IMPORTANT: remember to disconnect() the observer in beforeDestroy,
 * See SessionsView.vue for example.
 *
 * @param el
 * @param callback
 * @returns MutationObserver
 */
export const initDomObserverForA11yAttrFixes = function(
    el: HTMLElement,
    callback: MutationCallback
) {
    // Select the node that will be observed for mutations
    const targetNode = el;

    // Options for the observer (which mutations to observe)
    const config = {
        attributes: false, // this observer is only for attr fixes. if this is enabled an infinite loop will occur.
        childList: true,
        subtree: true
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);

    return observer;
};
