import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { getApiClient } from "@/services/api";
import { SiteNavigationItem, SiteOptions } from "@/types/interfaces";

const endpoint = "options";

@Module
export default class OptionsModule extends VuexModule {
    options = {
        helpContent: "",
        questionsOfTheDay: [],
        additionalInfoContent: "",
        bannerAds: [],
        activeNavigation: [],
        tags: [],
        actionIconSettings: {
            enabled: false,
            header: "",
            icons: []
        },
        pollSettings: {
            showResultsInSidebar: false
        },
        alertMessages: []
    } as SiteOptions;

    get activeNavItems() {
        return this.options.activeNavigation || [];
    }

    @Mutation
    public setOptions(data: SiteOptions) {
        const keys = Object.keys(data);

        keys.forEach((key) => {
            const realKey = key as keyof SiteOptions;
            this.options[realKey] = data[realKey] as any;
        });
    }

    @Action({ commit: "setOptions" })
    async getOptions(optionList: Array<string>) {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`/${endpoint}/${optionList.join(",")}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        return resolve({});
                    } else {
                        return reject(error);
                    }
                });
        });
    }

    @Action({ rawError: true })
    async updateOptions(payload: SiteOptions) {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .put(`/${endpoint}`, payload, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    }

    @Action({})
    async getNavItemByName(name: string) {
        if (!this.context.getters.activeNavItems.length) {
            await this.context.dispatch("getOptions", ["activeNavigation"]);
        }

        return this.context.getters.activeNavItems.find(
            (item: SiteNavigationItem) => item.name == name
        );
    }
}
