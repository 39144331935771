<template>
    <div class="videoWrapper">
        <video ref="videoPlayer" class="video-js" preload="auto">
            <!-- TODO: these should come from a `captions` object -->
            <track
                v-if="sessionData && sessionData['captions-es']"
                kind="captions"
                :src="sessionData['captions-es']"
                srclang="es"
                label="Espanol"
            />
            <track
                v-if="sessionData && sessionData['captions-en']"
                kind="captions"
                :src="sessionData['captions-en']"
                srclang="en"
                label="English"
            />
        </video>
    </div>
</template>
<script>
import videojs from "video.js";

import { mapGetters } from "vuex";

export default {
    name: "VideoPlayer",
    props: {
        startAt: {
            type: Number,
            default: 0
        },
        options: {
            type: Object,
            default() {
                return {
                    controls: false
                };
            }
        },
        sessionData: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            player: null,
            lastUpdate: null
        };
    },
    computed: {
        ...mapGetters(["isProduction"])
    },
    created() {
        this.lastUpdate = Date.now();
    },
    mounted() {
        const videoElement = this.$refs.videoPlayer;

        // first
        this.handleOptions();

        // then
        this.player = videojs(videoElement, this.options, () => {
            // console.log("player ready");

            this.player.on("loadedmetadata", () => {
                if (this.startAt && this.startAt < videoElement.duration) {
                    if (0 <= this.startAt) {
                        videoElement.currentTime = this.startAt;
                    }
                }

                // Get all text tracks for the current player.
                const tracks = this.player.textTracks();

                for (let i = 0; i < tracks.length; i++) {
                    const track = tracks[i];

                    // turn off embedded subtitles by default
                    if (track.kind === "subtitles") {
                        track.mode = "disabled";
                    }
                }
            });

            this.player.on("canplay", () => {
                // console.log("player play");
                // this.player.play();
            });

            this.player.on("timeupdate", () => {
                this.logWhilePlaying(videoElement.currentTime);
            });

            this.player.on("play", () => {
                this.logVideoPlay();
            });

            this.player.on("ended", () => {
                // console.log("player ended");

                this.player.exitFullscreen();
                this.player.hasStarted(false);
            });
        });
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose();
        }

        if (document.pictureInPictureElement) {
            document.exitPictureInPicture();
        }
    },
    methods: {
        handleOptions() {
            // make modifications to this.options
        },
        logWhilePlaying(watchTime) {
            const currentTime = Date.now();
            if ((currentTime - this.lastUpdate) / 60000 > 1) {
                this.lastUpdate = currentTime;
                const videoURL = this.options.sources[0].src;
                const lastSlashIndex = videoURL.lastIndexOf("/");
                const videoName = videoURL.substring(lastSlashIndex);
                let sessionId;
                let sessionName;
                // console.log(this.$props);
                if (this.$props.sessionData) {
                    sessionId = this.$props.sessionData.sessionId
                        ? this.$props.sessionData.sessionId
                        : null;
                    sessionName = this.$props.sessionData.title
                        ? this.$props.sessionData.title
                        : null;
                }

                const logData = {
                    type: `VideoViewTime`,
                    videoURL,
                    videoName,
                    sessionId,
                    sessionName,
                    watchTime
                };

                this.$store
                    .dispatch("appendLogEntry", logData)
                    .catch((err) => console.log(err));
            }
        },
        logVideoPlay() {
            const videoURL = this.options.sources[0].src;
            const lastSlashIndex = videoURL.lastIndexOf("/");
            const videoName = videoURL.substring(lastSlashIndex);
            let sessionId;
            let sessionName;
            // console.log(this.$props);
            if (this.$props.sessionData) {
                sessionId = this.$props.sessionData.sessionId
                    ? this.$props.sessionData.sessionId
                    : null;
                sessionName = this.$props.sessionData.title
                    ? this.$props.sessionData.title
                    : null;
            }

            const logData = {
                type: `VideoView`,
                videoURL,
                videoName,
                sessionId,
                sessionName
            };

            this.$store
                .dispatch("appendLogEntry", logData)
                .catch((err) => console.log(err));
        }
    }
};
</script>
<style lang="scss">
@import "../../../node_modules/video.js/dist/video-js.css";
@import "../../styles/components/video.scss";
</style>
