





















































































































































import store from "@/store";
import Vue from "vue";
import svgTwitter from "@/components/svg/svg-twitter.vue";
import svgLinkedin from "@/components/svg/svg-linkedin.vue";
import svgInstagram from "@/components/svg/svg-instagram.vue";
import svgFeedBurner from "@/components/svg/svg-feedburner.vue";
import svgYoutube from "@/components/svg/svg-youtube.vue";
import svgFacebook from "@/components/svg/svg-facebook.vue";
import svgPinterest from "@/components/svg/svg-pinterest.vue";
import eventHub from "@/event-hub";
import { a11yFixBuefyNotificationAriaAttrs } from "@/services/a11y";

import TwitterWidget from "@/components/shared/TwitterWidget.vue";
import { IPageOptions } from "@/types/interfaces";

interface SocialMediaAccount {
    facebook?: string;
    twitter?: string;
    linkedin?: string;
    instagram?: string;
    youtube?: string;
    feedburner?: string;
}
export default Vue.extend({
    data() {
        return {
            publicPath: process.env.BASE_URL,

            /**
             * Intentionally a hard coded string.
             *
             * Syntax:
             * month-day-year / (number to bump / reset if date is changed)
             * example: `01-01-21 / 2`
             *
             */
            lastUpdateDate: `07-19-21 / 3`
        };
    },
    components: {
        "svg-twitter": svgTwitter,
        "svg-likedin": svgLinkedin,
        "svg-instagram": svgInstagram,
        "svg-feedburner": svgFeedBurner,
        "svg-youtube": svgYoutube,
        "svg-facebook": svgFacebook,
        "svg-pinterest": svgPinterest,
        TwitterWidget
    },
    directives: {
        addbuefynotification: {
            bind: function(el: HTMLElement) {
                eventHub.$emit("BuefyNotificationAppVersion", el);
            }
        }
    },
    // computed
    computed: {
        isProduction(): boolean {
            return store.getters.isProduction;
        },

        footerOptions(): Record<string, any> {
            return store.getters.getPageOptions("footer");
        },

        mainFooterContent(): Record<string, any> {
            return this.footerOptions?.content?.mainContent
                ? this.footerOptions?.content?.mainContent
                : "";
        },

        socialMediaAccount(): SocialMediaAccount {
            return store.getters.socialMediaAccount;
        },

        hasSocialMedia(): boolean {
            const socialMediaAccount = (this as any).socialMediaAccount;

            const keys = Object.keys(socialMediaAccount);

            let hasSocial = false;

            keys.forEach((key) => {
                if (socialMediaAccount[key] && socialMediaAccount[key] != "") {
                    hasSocial = true;
                }
            });

            return hasSocial;
        },

        footerBG(): string {
            const footerImage = store.getters.layoutImages.footer || "";
            const path = `${this.publicPath}footer-images/`;
            let returnValue = "";

            if (footerImage) {
                returnValue = `background-image:url(${path}${footerImage})`;
            }

            return returnValue;
        },

        footerLogo(): string {
            return this.publicPath + "logos/" + store.getters.footerLogo;
        },

        isSuperUser(): boolean {
            return store.getters.isSuperUser;
        },

        useTwitterFeed(): boolean | undefined {
            const footerOptions = this.footerOptions as IPageOptions;

            return footerOptions.useFooterTwitterFeed;
        }
    },

    // lifecycle
    created() {
        eventHub.$on(
            "BuefyNotificationAppVersion",
            (this as any).handleBuefyNotificationFixes
        );
    },

    beforeDestroy() {
        eventHub.$off(
            "BuefyNotificationAppVersion",
            (this as any).handleBuefyNotificationFixes
        );
    },

    methods: {
        logout() {
            store.dispatch("logout");
        },

        handleBuefyNotificationFixes(el: HTMLElement) {
            a11yFixBuefyNotificationAriaAttrs(el);
        }
    }
    // Methods
});
