<template>
    <div
        class="bg-splash home-container "
        :class="{ 'has-animated-bg': pageOptions.hasAnimatedBackground }"
    >
        <section
            class="hero flex justify-center gate-bg"
            :class="[
                boxPositioned,
                {
                    'bg-repeat': pageOptions['bgRepeat']
                }
            ]"
            :style="
                screenSize >= '1024'
                    ? 'background:url(' + splashImage + ')'
                    : 'background:url(' + splashImageMobile + ')'
            "
        >
            <span
                class="hidden md:block"
                v-if="
                    pageOptions.splashGateText &&
                        pageOptions.splashGateText.isActive
                "
                :class="pageOptions.splashGateText.classToLoad"
                v-html="pageOptions.splashGateText.textContent"
            ></span>
            <!--
				TODO
				fact-animated-bg needs to removed,
				need to use the iframe / animatedBackgroundPath setup as privatebrands does
			-->
            <fact-animated-bg
                v-if="pageOptions.animatedBackgroundName == 'fact'"
            />

            <iframe
                v-else-if="
                    pageOptions.hasAnimatedBackground &&
                        pageOptions.animatedBackgroundPath
                "
                class="w-screen h-screen fixed overflow-hidden"
                scrolling="no"
                :src="
                    pageOptions.assetsHost + pageOptions.animatedBackgroundPath
                "
            ></iframe>

            <div v-else-if="splashImage == '/splash/'" class="mask"></div>

            <div
                v-if="boxPositioned"
                class="entry-card text-center relative"
                :class="[entryCardClass, marginPositioned]"
            >
                <div class="flex flex-col items-center mb-6 w-full">
                    <div v-if="pageOptions.videoBackground" class="w-full">
                        <video-component
                            :options="{
                                autoplay: true,
                                loop: true,
                                controls: false,
                                muted: true,
                                sources: [
                                    {
                                        src: pageOptions.videoBackground,
                                        type: 'application/x-mpegURL',
                                        https: ''
                                    }
                                ]
                            }"
                        />
                    </div>
                    <object
                        v-else-if="pageOptions.animatedSvg"
                        type="image/svg+xml"
                        class="name-logo mb-4 w-full md:w-1/2"
                        :data="pageOptions.animatedSvg"
                        aria-hidden="true"
                    ></object>
                    <img
                        v-else-if="logoPath"
                        class="name-logo ok-123"
                        :src="logoPath"
                        alt=""
                        aria-hidden="true"
                    />
                    <h3
                        v-if="slogan"
                        class="mb-0 mt-6 lg:px-0 slogan-text leading-none"
                        :class="
                            pageOptions.sloganFontSize
                                ? pageOptions.sloganFontSize
                                : 'text-3xl'
                        "
                    >
                        {{ slogan }}
                    </h3>
                </div>

                <div
                    v-if="conferenceName && pageOptions.displayConferenceName"
                    class="w-full text-center"
                    :class="!pageOptions.disableCardUI ? 'max-w-md' : ''"
                >
                    <h1
                        class="conference-name font-heading leading-snug"
                        :class="[
                            cssVariables['--conferenceName-custom--classes']
                        ]"
                    >
                        {{ conferenceName }}
                    </h1>
                </div>
                <h2
                    v-if="conferenceYear"
                    class="conference-text-color leading-none"
                    :class="[cssVariables['--conferenceYear-custom--classes']]"
                    v-html="conferenceYear"
                ></h2>
                <!-- TODO: When we use other statement to be display in gate rather than the conference name  -->
                <div
                    v-else-if="
                        displaySlogan && !pageOptions.displayConferenceName
                    "
                    class="w-full text-center"
                    :class="!pageOptions.disableCardUI ? 'max-w-md' : ''"
                >
                    <h3
                        class="conference-year title  mb-0 text-lg font-heading"
                        :class="
                            pageOptions.splashGateText.isActive
                                ? 'lg:text-xl'
                                : 'lg:text-3xl'
                        "
                    >
                        {{ displaySlogan }}
                    </h3>
                </div>

                <b-notification
                    v-if="isAuthenticated && !isAuthorized && !isLoadingUser"
                    class="my-6 text-left"
                    type="is-danger"
                    has-icon
                    :closable="false"
                >
                    <span v-html="unauthorizedMessage"></span>
                </b-notification>

                <b-notification
                    v-if="!isSiteActive"
                    type="is-info"
                    has-icon
                    :closable="false"
                    class="my-6 text-left"
                    key="meeting-has-ended"
                >
                    This meeting has ended.
                </b-notification>

                <b-notification
                    v-if="hasServerError"
                    class="my-6 text-left"
                    type="is-danger"
                    has-icon
                    :closable="false"
                >
                    <span>
                        There was an error logging you in.
                        <a :href="authConfig.logoutURL"
                            >Click here to log out</a
                        >
                        and attempt to log in again. If you continue to receive
                        this message, please contact
                        <a href="mailto:bespeakesupport@matrixgroup.net"
                            >bespeakesupport@matrixgroup.net</a
                        >.
                    </span>
                </b-notification>

                <div
                    v-else-if="!isLoadingUser"
                    key="meeting-is-active"
                    class="my-6 flex flex-col items-center"
                >
                    <button
                        v-if="isAuthenticated && !isAuthorized"
                        @click="logoutUser"
                        class="button is-primary  pill-button pill-button is-size-4 pt-1"
                        :class="[gatePageButton]"
                    >
                        {{ buttonTexts.logout }}
                    </button>
                    <b-button
                        v-else
                        @click="goToMeeting"
                        class="button  pill-button text-lg"
                        :class="[gatePageButton]"
                    >
                        {{ pageOptions.buttonPlaceholder || buttonTexts.enter }}
                    </b-button>

                    <div
                        v-if="hostLogoGate"
                        class="w-3/4 flex items-center justify-center mt-8"
                    >
                        <img :src="hostLogoGate" alt="" />
                    </div>
                </div>
                <Spinners v-else class="h-auto" />

                <p class="is-error my-4" v-if="loginError">{{ loginError }}</p>
                <p class="my-4">
                    Virtual meeting by
                    <a
                        href="https://www.matrixgroup.net/BeSpeake"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="has-text-weight-semibold bespeake-color"
                        >BeSpeake</a
                    ><sup class="text-xl">&reg;</sup>
                </p>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import VideoComponent from "@/components/shared/VideoComponent.vue";
import FactAnimatedBg from "@/components/FACT/FactAnimatedBg.vue";
import Spinners from "@/components/utilities/Spinners.vue";

export default {
    data() {
        return {
            path: window.location.href,
            publicPath: process.env.BASE_URL,
            boxPositioned: "",
            marginPositioned:
                "" /** Default position position when log in box is centered */,
            screenSize: ""
        };
    },
    components: {
        VideoComponent,
        "fact-animated-bg": FactAnimatedBg,
        Spinners
    },
    computed: {
        ...mapState(["themeConfig", "loginError", "isLoadingUser"]),
        ...mapGetters([
            "conferenceName",
            "displaySlogan",
            "conferenceYear",
            "authConfig",
            "isAuthenticated",
            "isAuthorized",
            "gateLogo",
            "logoGate",
            "isSiteActive",
            "authorizer"
        ]),

        splashImage() {
            if (this.$store.getters.splashImage.S3Url) {
                return this.$store.getters.splashImage.S3Url;
            } else if (this.$store.getters.splashImage.local) {
                return (
                    this.publicPath +
                    "splash/" +
                    this.$store.getters.splashImage.local
                );
            } else {
                return "";
            }
        },
        splashImageMobile() {
            if (this.$store.getters.splashImage.mobile) {
                return (
                    this.publicPath +
                    "splash/" +
                    this.$store.getters.splashImage.mobile
                );
            } else {
                return "";
            }
        },
        hostLogoGate() {
            if (this.$store.getters.hostLogoGate) {
                return (
                    this.publicPath + "gate/" + this.$store.getters.hostLogoGate
                );
            }
            return false;
        },
        pageOptions() {
            return this.$store.getters.getPageOptions("gate");
        },

        logoPath() {
            if (this.gateLogo) {
                return this.publicPath + "logos/" + this.gateLogo;
            } else {
                return "";
            }
        },
        slogan() {
            return this.$store.getters.slogan;
        },
        buttonTexts() {
            const text = this.pageOptions.buttonTexts;

            return {
                enter: text && text.enter ? text.enter : "Enter Meeting",
                logout: text && text.logout ? text.logout : "Logout"
            };
        },
        gatePageButton() {
            const getConfig = this.$store.getters.cssVariables;
            return getConfig["--gatePageButton"]
                ? getConfig["--gatePageButton"]
                : "is-primary";
        },
        entryCardClass() {
            const defaultClasses =
                "rounded-xl p-4 md:px-10 py-8 mx-4  w-auto";

            const maxWidthLogin = this.cssVariables['--gate-max-w'] ? this.cssVariables['--gate-max-w'] : 'max-w-sm'
            if (this.pageOptions.disableCardUI) {
                return "";
            } else {
                return this.pageOptions?.splashGateText?.isActive
                    ? `${defaultClasses} 'mt-8'`
                    : `${defaultClasses}  ${maxWidthLogin}`;
            }
        },
        cssVariables() {
            return this.themeConfig.cssVariables;
        },
        unauthorizedMessage() {
            return this.themeConfig.unauthorizedMessage
                ? this.themeConfig.unauthorizedMessage
                : "This site is only available to registered attendees.";
        },
        hasServerError() {
            const params = new URLSearchParams(window.location.search);

            const error = params.get("error");
            return Boolean(error);
        }
    },
    methods: {
        goToMeeting() {
            const authConfig = this.authConfig;
            // make sure there is a stored token so users don't get stuck
            // in the app in a state unable make API calls
            const storedToken = JSON.parse(
                localStorage.getItem("token") || "{}"
            );
            if (storedToken.idToken && this.isAuthorized) {
                this.$router.push("/");
            } else if (this.themeConfig.forceAuth) {
                this.authorizer.getAuthToken(authConfig);
            } else {
                this.$router.push("/");
            }
        },
        logoutUser() {
            this.$store.dispatch("logout");
        },
        getBoxPositionConfig() {
            switch (this.pageOptions.loginBoxPosition) {
                case "left":
                    this.boxPositioned = "items-start";
                    this.marginPositioned = "left-align";
                    break;
                case "right":
                    this.boxPositioned = "items-center lg:items-end";
                    this.marginPositioned = "right-align";
                    break;
                default:
                    this.boxPositioned = "items-center";
                    this.marginPositioned = "";
            }
        }
    },
    async created() {
        this.getBoxPositionConfig();
        this.screenSize = window.screen.width;
    }
};
</script>

<style lang="scss" scoped>
@import "../styles/views/gate.scss";
</style>
